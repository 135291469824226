.create-survey-container {
  margin-top: 3.5rem;

  h2 {
    &.cs-page-header {
      color: #656565;
      font-size: 28px;
      letter-spacing: 4px;
      word-spacing: 8px;
    }
  }

  .cs-row {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }

  .menu-div {
    height: 600px;
  }

  .qus-cr-div {
    border: 1px solid #cccccc;
    margin-bottom: 30px;

    .q-no {
      font-size: 15px;
      color: #5f5f5f;
    }

    .save-btn-row {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .btn-cancel {
        font-size: 13px;
        width: 135px;
        // height: 30px;
        background-color: #5f5f5f;
        color: snow;
        border-color: snow;
      }

      .btn-save {
        font-size: 13px;
        width: 135px;
        // height: 30px;
        background-color: #415bec;
        color: snow;
        border-color: snow;
      }
    }

    .create-qus-card {
      border-radius: unset;
      padding: 10px 10px 10px 10px;

      .close {
        display: flex;
        justify-content: flex-end;
        border: 0px;
      }

      .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #cccccc;
        border-bottom: 0;

        .form-select-sm {
          width: 225px;
        }
      }

      .card-body {
        border: 3px solid #cccccc;

        .q-editor {
          .rdw-editor-main {
            border: 1px solid #cccccc;
            padding: 10px;
            min-height: 250px;
          }
        }

        .qus-options, .qus-done-div {
          background: #f3f3f3;
          border: 1px solid #cccccc;
          padding: 10px;
          margin: 2px 2px 0;

          h6 {
            font-weight: 400;
            font-size: 15px;
          }
        }

        .qus-options {
          .qus-options-type-area {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;

            .btn-secondary {
              border-radius: 30%;
              height: 1.3rem;
              width: 1rem;
              display: flex;
              justify-content: space-around;
              align-items: center;
            }

            .input-group {
              width: 85%;

              .form-control {
                padding: 0px;
                line-height: 0px;
              }
            }
          }

          .view-options-type-area {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            padding-bottom: 1px;

            .btn-secondary {
              border-radius: 30%;
              height: 1.3rem;
              width: 1rem;
              display: flex;
              justify-content: space-around;
              align-items: center;
            }

            .input-group-text {
              border: 0px;
            }

            .lb-name {
              font-size: 12px;
              padding: 5px 5px 5px 5px;
            }
          }
        }

        .qus-done-div {
          border-top: 0 !important;
          margin-top: 0 !important;
          margin-bottom: 2px;
        }
      }
    }

    .container-lg, .MuiCardContent-root {
      padding: 0 !important;

      .card-header {
        display: flex;
        justify-content: space-between;

        .q-title {
          display: inline-flex;
          font-size: 13px;
          width: 60%;
        }
      }

      .view-qc {
        margin-bottom: 10px;

        .view-options-type-area {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 30px;

          .btn-secondary {
            border-radius: 30%;
            height: 1.3rem;
            width: 1rem;
            display: flex;
            justify-content: space-around;
            align-items: center;
          }

          .input-group-text {
            border: 0px;
            background-color: #ffffff;
          }

          .lb-name {
            font-size: 12px;
            padding: 5px 5px 5px 5px;
          }
        }
      }
    }

    .save-btn-div {
      border-bottom: 1px solid #cccccc;
      display: flex;
      justify-content: space-between;

      .btn {
        padding: .135rem .8rem;
        font-size: 13px;
        border-radius: 4px;
        height: 35px;
        margin-top: 6px;
      }

      .sur-name-div{
        width: 70%;
        .sur-name-field {
          min-width: 60%;
        }
      }
      .sur-info-div{
        width: 70%;
        .sur-info-field {
          min-width: 60%;
        }
      }


      .code-val {
        font-size: 12px;
        color: red;
      }
    }

    .title-row {
      padding: 10px 5px 25px 5px;
      display: flex;
      margin-bottom: 30px;
      // justify-content: space-between;
      // align-items: center;
      .title-field {
        width: 90%;
      }

      .group-1 {
        width: 200px;
        display: flex;
        flex-direction: row;
      }

      .btn-secondary {
        height: 35px;
        width: 90px;
      }

      .btn-edit {
        width: 45px;
        cursor: default;
        pointer-events: none;

        &:hover {
          background: transparent !important;
        }
      }
    }

    .logo-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    .view-img-row {
      margin-top: 5px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      flex-direction: row;

      .lft-img {
        width: 45px;
        height: 45px;
      }

      .rgt-img {
        width: 45px;
        height: 45px;
      }
    }

    .add-ques-card {
      margin: 15px;
      border-radius: 25px;
      padding: 25px;
      display: flex;
      align-items: center;
      margin-top: 25px;
    }

    .q-editor {
      padding: 2px;
    }

    .save-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 400px;
      bgcolor: #f8f8f8;
      border: 2px solid #000;
      boxShadow: 24;
      p: 4;

      //.sv-modal-nn-btn{
      //  width: 10px;
      //}
      //.sv-modal-yes-btn{
      //  width: 10px;
      //}

    }


  }

  .preview-div {
    border: 1px solid #cccccc;
  }

  .library-div {
    border: 1px solid #cccccc;

    .library-header {
      border-bottom: 1px solid #cccccc;
    }

    .list-group {
      border-radius: 0;

      .list-group-item {
        background: transparent;
        border: none;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 400;
        }

        button {
          background: #eaeaea;
          border: none;
          border-radius: 50%;
        }
      }
    }
  }

}
