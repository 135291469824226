.survey-list-container{
    margin-top: 3.5rem;
  .active-dropDown{
    padding-bottom: 10px;
  }
    .survey-list-card{
      border-radius: 30px;
      h2{
        &.survey-list-card-header{
          color: #656565;
          font-size: 28px;
          letter-spacing: 4px;
          word-spacing: 8px;
        }
      }
  
      .survey-item{
        background: #eaeaea;
        padding: 20px 30px 20px 20px;
        border-radius: 15px;
        margin-bottom: 12px;
        .survey-item-name{
          h5{
            font-size: 22px;
            text-transform: uppercase;
            letter-spacing: 2px;
            word-spacing: 3px;
            color: #1371c9;
            margin-bottom: 12px;
          }
          h6{
            color: #4a4a4a;
            font-weight: 200;
            letter-spacing: 1.125px;
          }
        }
        .survey-qus-count{
          h4{
            font-size: 22px;
            letter-spacing: 4px;
            color: #212121;
          }
          h6{
            color: #4a4a4a;
            font-weight: 400;
            letter-spacing: 1px
          }
        }
        .survey-btn-area{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .archive:hover{
            color: #238023;
          }
          .delete:hover{
            color: #F93154;
          }
          .question-answer:hover{
            color: #39C0ED;
          }
          .edit:hover{
            color: #FB8C00;
          }
        }
      }
  
      .btn-create{
        padding-right: 16px;
        text-align: end;
        .btn{
          background: #FF9800;
          border: unset;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 500;
        }
      }
    }
  }
  

  
  
  