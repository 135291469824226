@import './assert/styles/surveyList';
@import './assert/styles/surveyHome';
@import './assert/styles/createSurvey';
@import './assert/styles/assignSurvey';
@import './assert/styles/respondentHome';
@import './assert/styles/respondentList';
@import './assert/styles/createRespondents';
@import './assert/styles/previewSurvey';
@import './assert/styles/renderSurvey';


.file-select-btn{
  display: flex;
  justify-content: space-evenly;
}


.excel-icon {
  width: 35px;
  hight: 35px;
}

.submit-alert{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin: 10%;

  .MuiAlert-icon{
    font-size: 152px;
  }

}

.alert-body{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  .btn{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.publish-btn-group{
  display: flex;
  justify-content: space-evenly;
  .btn-edit-sDetails{
    background-color: #ef7a06;
    color: #fff;
  }
}

.qus-options {
  .qus-options-type-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;

    .btn-secondary {
      border-radius: 30%;
      height: 1.3rem;
      width: 1rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .input-group {
      width: 85%;

      .form-control {
        padding: 0px;
        line-height: 0px;
      }
    }
  }

  .view-options-type-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding-bottom: 1px;

    .btn-secondary {
      border-radius: 30%;
      height: 1.3rem;
      width: 1rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .input-group-text {
      border: 0px;
    }

    .lb-name {
      font-size: 12px;
      padding: 5px 5px 5px 5px;
    }
  }
}
