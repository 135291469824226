.respondent-list-container{
  margin-top: 3.5rem;
  h2{
    &.respondent-list-card-header{
      color: #656565;
      font-size: 28px;
      letter-spacing: 4px;
      word-spacing: 8px;
    }
  }
  .respondent-list-card{
    //border-radius: 30px;

    .rg-item{
      background: #eaeaea;
      padding: 20px 20px 20px 0px;
      border-radius: 10px;
      margin-bottom: 12px;
    ;
      .rg-item-name{
        h5{
          font-size: 22px;
          text-transform: uppercase;
          letter-spacing: 2px;
          word-spacing: 3px;
          color: #1371c9;
          margin-bottom: 12px;
        }
        h6{
          color: #4a4a4a;
          font-weight: 400;
          letter-spacing: 1.125px;
        }
      }
      .rg-res-count{
        h6{
          color: #2282ec;
          font-weight: 500;
          letter-spacing: 1px;
          text-decoration: underline;
        }
        h7{
          //letter-spacing: 4px;
          color: #212121;
        }
      }
      .rg-btn-area{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .delete:hover{
          color: #F93154;
        }
        .question-answer:hover{
          color: #39C0ED;
        }
        .edit:hover{
          color: #FB8C00;
        }
      }
    }

    .rg-btn-create{
      padding-right: 16px;
      text-align: end;
      .btn{
        background: #FF9800;
        border: unset;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
      }
    }
  }
}




