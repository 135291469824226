.as-sur-container{
  margin-top: 3.5rem;

  .main-crd{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: flex-end;

    .main-header{
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .sel-survey-card{
    width: 550px;
    margin-bottom: 1rem;
  }
  .usr-group-card{
    width: 550px;
    margin-bottom: 1rem;
    .groupErr{
      color: red;
      font-size: 0.8rem;
    }
  }
  .sch-survey-card{
    width: 550px;
    margin-bottom: 1rem;
  }

  .sub-btn{
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 550px;

    .btn-snd-survey{
      width: 150px;
    }
  }
  

}
