.create-respondent-container {
  margin-top: 3.5rem;

  .cus-box{
    width: 50%;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .prod-box{
    width: 50%;
    padding-bottom: 35px;
    padding-top: 15px;
  }
  .save-btn{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 25px;
    padding-top: 25px;
    .btn{
      width: 10rem;
      background-color: #FB8C00;
    }
  }

  .respondents-tbl{
    margin-top: 3px;
  }


}
