.survey-home-container{
    margin-top: 3.5rem;
    .survey-card{
      border-radius: 30px;
        h2{
          &.survey-home-card-header{
            color: #656565;
            font-size: 28px;
            letter-spacing: 4px;
            word-spacing: 8px;
          }
        }
      .survey-home-item{
        background: #eaeaea;
        padding: 20px 30px;
        border-radius: 15px;
        .card-img-top{
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        .image-card{
          min-height: 10px;
          min-width: 50px;
        }
      }

      .btn-create{
        padding-top: 10px;
        padding-right: 16px;
        text-align: end;
        .btn{
          background: #FF9800;
          border: unset;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 500;
        }
      }

    }
}
