.render-survey-container{
    margin-top: 0rem;
    padding-top: 10vh;
    padding-left: 20vh;
    padding-right: 20vh;

    .card{
        .card-title{
            //display: flex;
            height: 100px;
            background-color: #a8a6a6;
            border-bottom: 3px solid #FB8C00;
            justify-content: space-evenly;
            h2 {
                &.survey-title {
                    margin-top: 1.5rem;
                    color: #100f0f;
                    font-size: 28px;
                    letter-spacing: 1px;
                    word-spacing: 4px;
                    font-family: sans-serif;
                }
            }
            .left-logo-view{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                .lft-img{
                    width: 60px;
                    height: 60px;
                }
            }
            .right-logo-view{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                .rgt-img{
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .card-footer{
            font-size: 8px;
        }

        .sr-btn-div{
            //border-top: 1px solid #cccccc;
            //border-bottom: 1px solid #cccccc;
            display: flex;
            justify-content: center;
            //padding-bottom: 10px;

            .btn{
                //padding: .135rem .8rem;
                //font-size: 15px;
                //border-radius: 4px;
                //height: 35px;
                //margin-top: 6px;
                //background-color: #ffc107;
                //color: black;
                //border-color: snow;
                //height: 35px;
            }
        }

        .view-options-type-area{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            .btn-secondary{
                border-radius: 30%;
                height: 1.3rem;
                width: 1rem;
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
            .input-group-text{
                border: 0px;
                background-color: #ffffff;
            }

            .lb-name{
                font-size: 12px;
                padding: 5px 5px 5px 5px;
            }
        }

        .s-description{
            margin-left: 1rem;
            margin-top: 1rem;
        }

    }
    .q-card{
        padding: 10px 10px;
        margin-bottom: 5px;
    }
    .inline-text{
        display: inline-flex;
    }
}