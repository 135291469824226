.pre-container{
  .card{
    .card-header{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: column;
      background-color: #a8a6a6;
      h4{
        font-size: 1rem;
        font-family: sans-serif;
        display: flex;
        justify-content: center;
        margin-top: 5px;
      }

      .left-logo-view{
        display: block;
        border: 0px solid #cccccc;
        .lft-img{
          width: 55px;
          height: 35px;
        }

      }
      .right-logo-view{
        display: block;
        border: 0px solid #cccccc;
        .rgt-img{
          width: 45px;
          height: 45px;
        }
      }
    }

    .s-description{
      margin-left: 1rem;
      margin-top: 1rem;
    }

    .card-body{
      border-bottom: 1px solid #cccccc;
      background-color: rgba(0,0,0,.03);
      .card-title{
        display: flex;
        font-size: 13px;
        font-family: sans-serif;
      }
      .view-options-type-area{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        .btn-secondary{
          border-radius: 30%;
          height: 1.3rem;
          width: 1rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .input-group-text{
          border: 0px;
          background-color: #e9ecef;
        }

        .lb-name{
          font-size: 12px;
          padding: 5px 5px 5px 5px;
        }
      }

    }
  }
  .done-btn-div{
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

   .btn{
     padding: .135rem .8rem;
     font-size: 15px;
     border-radius: 4px;
     height: 35px;
     margin-top: 6px;
     background-color: #ffc107;
     color: black;
     border-color: snow;
    //  width: 135px;
     height: 35px;
   }
  }
  .preview-txt{
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .txt-area{
    width: 755px;
  }
}