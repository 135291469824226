.ug-hm-container{
  margin-top: 3.5rem;

  .respondent-card-header{
    display: flex;
    justify-content: center;
    color: #656565;
    font-size: 28px;
    letter-spacing: 4px;
    word-spacing: 8px;
    padding: 20px;
  }
  .ug-hm-img-card{
    padding: 10px 10px;
    border-radius: 15px;

    .btn-create{
      padding-top: 10px;
      padding-right: 16px;
      text-align: end;
      .btn{
        background: #FF9800;
        border: unset;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
      }
    }
  }
}
